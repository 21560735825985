import React, { useEffect, useState } from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import { Timer } from './';
import { 
    color, 
    StyledSectionTitle,
    WIDTH_MOBILE,
    useLocale
} from '../util';
import { 
    useAppSelector, 
    selectBlockStats
} from '../redux';

export function BlockInfo() {
    const locale = useLocale();
    const [timeLastBlock, setTimeLastBlock] = useState(0);
    const [timeLastUpdate, setTimeLastUpdate] = useState(0);

    const state = useAppSelector(selectBlockStats);
    const {
        txLenEst,
        txLenKnown,
        block
    } = state;

    useEffect(() => {
        let blockTimerLast = setInterval(() => {
            setTimeLastBlock((time) => time + 10);
        }, 10);

        let updateTimerLast = setInterval(() => {
            setTimeLastUpdate((time) => time + 10);
        }, 10);

        return () => {
            clearInterval(blockTimerLast);
            clearInterval(updateTimerLast);
        };
    }, []);

    useEffect(() => {
        setTimeLastBlock(0);
    }, [block]);

    useEffect(() => {
        setTimeLastUpdate(0);
    }, [state]);

    
    const indicatorHeight = (!!txLenEst && !!txLenKnown) ? Math.ceil(txLenKnown / txLenEst * 100) : 0;

    return (
        <>
            <StyledTxIndicator className={timeLastBlock > 15000 && txLenKnown === txLenEst ? 'waiting' : ''} height={indicatorHeight} />
            <StyledZIndex>
                <div className="blockNumber">
                    <StyledSectionTitle>
                        {locale.nextBlock}
                    </StyledSectionTitle>
                    <StyledBlockNumber>
                        <strong>{block}</strong>
                    </StyledBlockNumber>
                </div>
                <StyledFraction>
                    <div className="numerator">
                        <span className="label">{locale.knownTx}</span>
                        <span className={txLenKnown === txLenEst ? 'equal': ''}>{txLenKnown}</span>
                    </div> 
                    <div className="sep"></div>
                    <div className="denominator">
                        <span className="label">{locale.estTx}</span>
                        <span className={txLenKnown === txLenEst ? 'equal': ''}>{txLenEst}</span>
                    </div>
                </StyledFraction>
                
                <StyledTimers>
                    <div className="timer-wrap">
                        <span className="label">{locale.lastBlock}</span>
                        <span className={timeLastBlock > 15000 ? 'waiting' : ''}>
                            <Timer time={timeLastBlock} />
                        </span>
                    </div>
                    <div className="timer-wrap">
                        <span className="label">{locale.lastUpdate}</span>
                        <span><Timer time={timeLastUpdate} /></span>
                    </div>
                </StyledTimers>
                
            </StyledZIndex>
        </>
    );
};

const StyledTimers = styled.div`
    width: 100%;

    span {
        &.label {
            font-size: 14px;
            opacity: 0.5;
        }
        &.waiting {
            transition: color 0.1s linear 0s;
            color: ${color.orange};
        }
    }

    .dark-mode & span.waiting {
        color: ${color.orangeDark};
    }

    div.timer-wrap {
        margin-top: 4px;
        display: flex;
        flex-direction: row;
        flex-wrap: no-wrap;
        justify-content: space-between;
        align-items: center;
    }

    @media only screen and (max-width: ${WIDTH_MOBILE}) {
        margin-top: 20px;
        margin-right: 140px;
        max-width: 160px;
    }

    @media only screen and (max-width: 400px) {
        margin-right: 10px;
    }
`;

const StyledFraction = styled.div`
    font-size: 16px;
    text-align: right;
    max-width: 126px;

    .dark-mode & {
        span.equal {
            color: ${color.orangeDark};
        }
    }

    span {
        &.label {
            opacity: 0.5;
            font-size: 14px;
            margin-right: 10px;
        }
        &.equal {
            transition: color 0.1s linear 0s;
            color: ${color.orange};
        }

        span {
            text-align: right;
        }
    }

    .sep {
        width: 100%;
        height: 1px;
        margin: 8px auto 6px;

        background: rgba(0,0,0,.1);

        .mode-dark & {
            background: rgba(255,255,255,.1);
        }
    }

    @media only screen and (max-width: ${WIDTH_MOBILE}) {
        margin-left: 50px;
    }
`;

const StyledBlockNumber = styled.div`
    color: ${color.orange};
    margin-top: 5px;
    font-size: 24px;
    text-align: center;

    .dark-mode & {
        ${color.orangeDark};
    }
`;

const StyledZIndex = styled.div`
    position: relative;
    height: 100%;
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;

    @media only screen and (max-width: ${WIDTH_MOBILE}) {
        flex-direction: row;
        flex-wrap: wrap;
    }
`;

const AnimIndicatorDone = keyframes`
    100% {
        opacity: 0;
    }
    // 100% {
    //     background: ${color.blue};
    // }
`;

const StyledTxIndicator = styled.div<{height: number}>`
    transition: all 0.2s linear 0s, opacity 0.8s ease 0s;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 0;
    pointer-events: none;
    width: 100%;
    height: ${({height}) => height}%;
    background: ${color.orange};
    opacity: 0.15;

    &.waiting {
        opacity: 0;
        // animation: ${AnimIndicatorDone} 1.3s ease infinite;
    }

    @media only screen and (max-width: ${WIDTH_MOBILE}) {
        height: 100%;
        width: ${({height}) => height}%;
    }
`;