import { configureStore } from '@reduxjs/toolkit';
import {
    reducerGasStats,
    reducerGasCounts,
    reducerEthPrice,
    reducerHistoricalGas,
    reducerAverageGas
} from './slice';

export const store = configureStore({
    reducer: {
        gasStats: reducerGasStats,
        gasCounts: reducerGasCounts,
        ethPrice: reducerEthPrice,
        gasHistorical: reducerHistoricalGas,
        gasAverage: reducerAverageGas
    }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;