export const color = {
    font: '#333333',
    fontDark: '#fbfbfb',
    background: '#f9f6f6',
    backgroundDark: '#0f1011',
    header: '#ffffff',
    headerDark: '#181a1b',
    sectionBackground: '#ffffff',
    sectionBackgroundDark: '#181a1b',
    green: '#00c718',
    greenDark: '#41ff58',
    orange: '#ff7828',
    orangeDark: '#f67a30',
    blue: '#0060ff',
    blueDark: '#339aff',
    purple: '#9160f2',
    purpleDark: '#9161f2',
    badgeBackground: '#ebebeb',
    badgeBackgroundDark: '#303132',
    socialBlue: '#1da1f1',
    socialPurple: '#8596f6',
};


export const convertHexToRGBA = (hexCode: string, opacity: number) => {
    let hex = hexCode.replace('#', '');
    
    if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
    }    
    
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);

    return `rgba(${r},${g},${b},${opacity})`;
};