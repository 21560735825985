import React from 'react';
import styled from '@emotion/styled';
import { LogoTweet } from '../asset';
import {
    color, 
    useLocale,
    WIDTH_MOBILE
} from '../util';
import { 
    useAppSelector, 
    selectEthPriceUsd
} from '../redux';

export function EthPriceSocial() {
    const locale = useLocale();
    const usdPrice = useAppSelector(selectEthPriceUsd);

    return (
        <StyledEthSocialContainer>
            <span className="price">
                {`${locale.eth}: ${usdPrice ?? '(ERROR)'} USD`}
            </span>
            <StyledSocial>
                <a href="https://twitter.com/stab_io" target="_blank" rel="noreferrer">
                    <LogoTweet />
                </a>
            </StyledSocial>
        </StyledEthSocialContainer>
    );
}


const StyledEthSocialContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 12px;
    margin: 0 auto;
    max-width: 89%;

    .price {
        font-size: 14px;
        opacity: 0.6;
    }

    @media only screen and (max-width: ${WIDTH_MOBILE}) {
        padding-bottom: 9px;
    }
`;

const StyledSocial = styled.div`
    max-width: 300px;

    svg {
        max-width: 34px;
        max-height: 34px;
    }

    a {
        .bg {
            fill: ${color.fontDark};
            stroke: ${color.socialBlue};
            stroke-width: 1.5px;
        }

        .birb {
            fill: ${color.socialBlue};
        }

        &:hover {
            .birb {
                fill: ${color.background};
            }
            .bg {
                fill: url(#hover-gradient-light);
            }
        }
    }

    .mode-dark & {
        .bg {
            fill: ${color.backgroundDark};
        }

        &:hover {
            .birb {
                fill: ${color.fontDark};
            }
            .bg {
                fill: url(#hover-gradient-dark);
            }
        }
    }
`;