import React, { useState } from 'react';
import styled from '@emotion/styled';
import { 
  colorMode,
  LogoStab,
  LogoTitle
} from './asset';
import { 
  prefersColorMode, 
  toggleColorMode, 
  color, 
  StyledCenter,
  // StyledSection,
  StyledColumn,
  StyledColumnWrapper,
  WIDTH_MOBILE,
  useLocale
} from './util';
import {
  GasAverage,
  GasCounts,
  // GasDollarCosts,
  GasHistorical,
  GasStats,
  BlockInfo,
  EthPriceSocial,
} from './component';


function App() {
  const locale = useLocale();
  const [colorModePref, setColorModePref] = useState(prefersColorMode());

  return (
    <StyledApp className={`mode-${colorModePref}`}>
      <Header>
        <StyledCenter>
          <HeaderInner>
            <div className="logo">
              <span className="logo-img">
                <LogoStab />
              </span>
              <span className="logo-title">
                <LogoTitle />
              </span>
              <span className="logo-description">{locale.header}</span>
            </div>
            
            <ThemeToggle>
              <div onClick={() => setColorModePref(toggleColorMode())}>
                {colorMode()}
              </div>
            </ThemeToggle>
          </HeaderInner>
        </StyledCenter>
      </Header>

      <StyledCenter>
        <GasStats />
        <EthPriceSocial />
        <StyledColumnWrapper>
          <StyledColumn className="one-third">
            <BlockInfo />
          </StyledColumn>
          <StyledColumn className="two-third">
            <StyledGasCounts colorModePref={colorModePref} />
          </StyledColumn>
        </StyledColumnWrapper>

        {/* <StyledSection>
          <GasDollarCosts />
        </StyledSection> */}
        
        <GasHistorical colorModePref={colorModePref} />
        <GasAverage colorModePref={colorModePref} />
      </StyledCenter>
      <StyledFooter>
        &#169; {new Date().getFullYear()} stab
      </StyledFooter>
    </StyledApp>
  );
}


const StyledApp = styled.div`
  position: relative;
  display: inline-block;
  min-height: 100%;
  width: 100%;
  min-width: 350px;
  background: ${color.background};
  color: ${color.font};
  min-height: 100%;

  &.mode-dark {
    background: ${color.backgroundDark};
    color: ${color.fontDark};
  }
`;

const Header = styled.header`
  box-sizing: border-box; 
  position: relative;
  width: 100%;
  height: 54px;
  background: ${color.header};
  box-shadow: 0px -11px 26px 7px rgba(0,0,0,0.15);

  .mode-dark & {
    background: ${color.headerDark};
    box-shadow: 0px -12px 26px 7px rgba(0,0,0,0.15);
  }
`;

const StyledGasCounts = styled(GasCounts)`
  height: 100%;
  width: 100%;
`;

const HeaderInner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: space-between;
  align-items: center;

  .logo {
    margin-top: 2px;
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
    align-items: center;

    span {
      display: inline-block;
    }
  }

  .logo-img {
    svg {
      path {
        fill: url(#gradient);
      }

      .start {
        stop-color: ${color.orange};

        .mode-dark & {
          stop-color: ${color.blueDark};
        }
      }
      .end {
        stop-color: ${color.blue};

        .mode-dark & {
          stop-color: ${color.orangeDark};
        }
      }
    }
  }

  .logo-title {
    font-size: 13px;
    margin-left: 5px;

    svg {
      max-height: 22px;
      max-width: 60px;
      width: auto;

      path {
        fill: url(#gradient2);
      }

      .start {
        stop-color: ${color.orange};

        .mode-dark & {
          stop-color: ${color.blueDark};
        }
      }
      .end {
        stop-color: ${color.blue};

        .mode-dark & {
          stop-color: ${color.orangeDark};
        }
      }
    }
  }
  
  .logo-description {
    opacity: 0.6;
    margin-left: 10px;
    padding-left: 8px;
    border-left: 1px solid rgba(0,0,0,.1);

    @media only screen and (max-width: ${WIDTH_MOBILE}) {
      display: none !important;
    }

    .mode-dark & {
      border-left: 1px solid rgba(255,255,255,.2);
    }
  }

  svg {
    height: 38px;
    width: 38px;
    &.pos {
      margin: 0 0 0 -12px;
    }
    fill: ${color.font};

    .mode-dark & {
      fill: ${color.fontDark};
    }
  }
`;

const ThemeToggle = styled.div`
  transition: all 0.1s linear 0s;
  position: relative;
  width: 26px;
  height: 26px;
  cursor: pointer;

  div {
    position: relative;
    height: 100%;
    width: 100%;
  }

  svg {
    fill: ${color.font};
    height: 100%;
    width: 100%;

    .mode-dark & {
      fill: ${color.fontDark};
    }
  }

  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.8;
  }
`;

const StyledFooter = styled.div`
  text-align: center;
  color ${color.font};
  opacity: 0.35;
  font-size: 12px;
  margin: 40px 0 38px;

  .mode-dark & {
    color: ${color.fontDark};
  }
`;

export default App;
