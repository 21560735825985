import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

// Types
type DataBaseType = {
    readonly rapid: number;
    readonly fast: number;
    // readonly txKnown: number;
    // readonly txEst: number;
}

type HistoricalGasTimeDataType = {
    readonly time: number;
} & DataBaseType;
type HistoricalGasTimeType = {
    readonly payloadType: 'minute_1' | 'minute_10' | 'hour_1' | 'day_1';
    readonly data: HistoricalGasTimeDataType[];
}

type HistoricalGasBlockDataType = {
    readonly blocknumber: string;
} & DataBaseType;
type HistoricalGasBlockType = {
    readonly payloadType: 'block';
    readonly data: HistoricalGasBlockDataType[];
};

type HistoricalGasState = HistoricalGasTimeType | HistoricalGasBlockType;

// Initial State
const initialState = {
    payloadType: 'block',
    data: []
} as HistoricalGasState;

export const sliceHistoricalGas = createSlice({
    name: 'gasHistorical',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateData: (state, action: PayloadAction<HistoricalGasBlockDataType[] | HistoricalGasTimeDataType[]>) => {
            state.data = action.payload;
            
        },
        updatePayloadType: (state, action: PayloadAction<HistoricalGasBlockType['payloadType'] | HistoricalGasTimeType['payloadType']>) => {
            state.payloadType = action.payload;
        },
    },
});

export const { 
    updateData: updateHistoricalGas,
    updatePayloadType: updateHistoricalGasType
} = sliceHistoricalGas.actions;
export const selectHistoricalGas = (state: RootState) => state.gasHistorical;
export const { reducer: reducerHistoricalGas } = sliceHistoricalGas;

