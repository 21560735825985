import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

// Types
type GasCountsState = {
    readonly gasPrice: number;
    readonly count: number;
}[];

// Initial State
const initialState = [] as GasCountsState;

export const sliceGasCounts = createSlice({
    name: 'gasCounts',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        update: (state, action: PayloadAction<GasCountsState>) => {
            return action.payload;
        },
    },
});

export const { update: updateGasCounts } = sliceGasCounts.actions;
export const selectGasCounts = (state: RootState) => state.gasCounts;
export const { reducer: reducerGasCounts } = sliceGasCounts;

