const LS_KEY = 'colorPref';

/**
 * get color pref from localStorage, else get it from user preferences then set it as such
 * @returns string
 */
export function prefersColorMode() {
    const savedPref = localStorage.getItem(LS_KEY);
    if (savedPref === 'light' || savedPref === 'dark') {
        return savedPref;
    } else {
        if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
            localStorage.setItem(LS_KEY, 'dark');
            return 'dark';
        } else {
            localStorage.setItem(LS_KEY, 'light');
            return 'light';
        }
    }
}


export function toggleColorMode() {
    const savedPref = localStorage.getItem(LS_KEY);

    if (savedPref === 'light') {
        localStorage.setItem(LS_KEY, 'dark');
        return 'dark';
    } else if (savedPref === 'dark') {
        localStorage.setItem(LS_KEY, 'light');
        return 'light';
    } else if (!savedPref) {
        localStorage.setItem(LS_KEY, 'dark');
    }
    
    return 'dark';
}