import React, { useEffect, useRef, useState, useCallback, useMemo } from 'react';
import { scaleTime, scaleLinear } from '@visx/scale';
import styled from '@emotion/styled';
import Dropdown from 'react-bootstrap/Dropdown';
import { Group } from '@vx/group';
import { AxisBottom, AxisLeft } from '@vx/axis';
import { Bar } from '@vx/shape';
import { HeatmapRect } from '@visx/heatmap';
import { localPoint } from '@vx/event';
import { timeFormat } from 'd3-time-format';
import { 
    useTooltip, 
    TooltipWithBounds, 
    defaultStyles 
} from '@vx/tooltip';
import {
    color, 
    useLocale,
    StyledSection,
    StyledSectionTitle,
    StyledSectionDescription,
    StyledChartWrapper,
    StyledDropdownControls,
    useWindowSize,
    WIDTH_TABLET,
    WIDTH_MOBILE
} from '../util';
import { 
    useAppSelector, 
    selectAverageGas,
} from '../redux';

type GasAverageType = {
    readonly colorModePref: string;
}
type Bins = {
    readonly bin: number;
    readonly time: Date;
    readonly count: number;
    readonly fast: number;
    readonly rapid: number;
};
type Bin = {
    readonly bin: number;
    readonly bins: Bins[];
}[];


function zeroPad(num: number, numZeros: number) {
    var n = Math.abs(num);
    var zeros = Math.max(0, numZeros - Math.floor(n).toString().length );
    var zeroString = Math.pow(10,zeros).toString().substr(1);
    if( num < 0 ) {
        zeroString = '-' + zeroString;
    }

    return zeroString + n;
}

export function GasAverage({colorModePref}: GasAverageType) {
    const locale = useLocale();
    const { data } = useAppSelector(selectAverageGas);
    const ref = useRef<HTMLHeadingElement>(null);
    const [chartType, setChartType] = useState<'fast'|'rapid'>('fast');
    const [binData, setBinData] = useState(dataToBin(data));
    const [width, setWidth] = useState(0);
    const [windowWidth,] = useWindowSize();
    const height = 640;
    const margin = {
        left: 44,
        right: 6,
        top: 12,
        bottom: 40
    };

    // left: 44,
    // right: 32,
    // top: 12,
    // bottom: 100
    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;
    const labelColor = colorModePref === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(51, 51, 51,0.5)';
    const lineColor = colorModePref === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(51, 51, 51,0.05)';

    useEffect(() => {
        setWidth(ref.current ? ref.current.offsetWidth : 0);
    }, [windowWidth]);

    useEffect(() => {
        setBinData(dataToBin(data));
    }, [data]);

    function max<Datum>(data: Datum[], value: (d: Datum) => number): number {
        return Math.max(...data.map(value));
    }
    
    // function min<Datum>(data: Datum[], value: (d: Datum) => number): number {
    //     return Math.min(...data.map(value));
    // }
    
    // accessors
    const bins = (d: any) => d.bins;
    const count = (d: any) => chartType === 'fast' ? d.fast : d.rapid;

    const colorMax = useMemo((): number => {
        return max(binData, (d) => {
            return max(bins(d), count)
        });
    }, [chartType, count, binData]);

    const bucketSizeMax = max(binData, (d) => bins(d).length);

    const binWidth = xMax / binData.length;
    const binHeight = yMax / bucketSizeMax;
    
    // scales
    const bottomAxisScale = scaleTime({
        range: [0 + binWidth / 2, xMax - binWidth / 2],
        domain: [binData[0]?.bins[0]?.time, binData[binData.length - 1]?.bins[0]?.time],
    });
    const xScale = scaleLinear<number>({
        domain: [0, binData.length],
    });
    const yScale = scaleLinear<number>({
        domain: [0, bucketSizeMax],
    });
    const rectColorScale = useMemo(
        () => 
            scaleLinear<string>({
                range: colorModePref === 'dark' ? ['#20b57e', '#fb930d', '#db3c1f'] : [color.sectionBackground, '#ff4c2c'],
                domain: colorModePref === 'dark' ? [0, Math.floor(colorMax / 2.6), colorMax] : [0, colorMax]
            }),
            [colorMax, colorModePref]
    );
    const opacityScale = useMemo(
        () => 
            scaleLinear<number>({
                range: colorModePref === 'dark' ? [0.7, 1] : [0.1, 1],
                domain: [0, colorMax],
            }),
            [colorMax, colorModePref]
    );

    xScale.range([0, xMax]);
    yScale.range([yMax, 0]);

    const formatHour = (value: number) => {
        if (value === 24) return '';
        return `${zeroPad(value, 2)}:00`;
    }

    // Tooltip
    const tooltipStyles = {
        ...defaultStyles,
        background: colorModePref === 'dark' ? 'rgba(51, 51, 51,0.9)' : 'rgba(255,255,255,0.95)',
        border: `1px solid ${lineColor}`,
        maxWidth: `${windowWidth < 640 ? 150 : 300}px`,
        color: colorModePref === 'dark' ? color.fontDark : color.font,
        boxShadow: 'none',
        padding: '8px 10px',
        lineHeight: '20px'
    };
    const {
        tooltipData,
        tooltipLeft = 0,
        tooltipTop = 0,
        tooltipOpen,
        showTooltip,
        hideTooltip,
    } = useTooltip();
    const handleTooltip = useCallback((event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
        const { x, y } = localPoint(event) || { x: 0, y: 0 };
        
        const i0 = Math.floor((x - margin.left) / binWidth);
        const i1 = Math.floor(binHeight - ((y - 13) / binHeight));
        const targetBin = binData[i0]?.bins[i1];

        showTooltip({
            tooltipLeft: x,
            tooltipTop: y < 130 ? y - 16 : y - 80, 
            tooltipData: {
                count: chartType === 'fast' ? targetBin?.fast : targetBin?.rapid,
                time: targetBin?.time,
                future: !!targetBin
            }
        });
    }, [
        showTooltip, 
        xScale, 
        yScale, 
        margin.left, 
        binData,
        binHeight,
        binWidth,
        chartType
    ]);

    function dataToBin(data: {
        time: number,
        fast: number,
        rapid: number
    }[]): Bin {
        if (!data || !data.length) {
            return [];
        }
    
        let binResult = [];
        let dayResult: any[] = [];
        let binNumberDay = 0;
        let lastDay = new Date(data[0].time).getDate();
        let binNumberHour = 0;
    
        for (let i = 0, iLen = data.length; i < iLen; i++) {
            const hour = data[i];
            const time = new Date(hour.time);
            if (lastDay !== time.getDate()) {
                lastDay = time.getDate();
                binResult.push({
                    bin: binNumberDay,
                    bins: dayResult.reverse()
                });
                binNumberDay++;
                binNumberHour = 0;
                dayResult = [];
            }
            dayResult.push({
                bin: binNumberHour,
                count: chartType === 'fast' ? hour.fast : hour.rapid,
                fast: hour.fast,
                rapid: hour.rapid,
                time
            });
            if (i === iLen - 1) {
                binResult.push({
                    bin: binNumberDay,
                    bins: dayResult.reverse()
                });
                break;
            }
            binNumberHour++;
        }
        if (binResult.length === 8) {
            binResult.pop();
        }
        binResult = binResult.reverse();
        return binResult;
    } 

    return (
        <StyledSection>
            <StyledGasAverageHeader>
                <StyledGasAverageTitleContainer>
                    <StyledSectionTitle>{locale.avgGasPrice}</StyledSectionTitle>
                    <StyledSectionDescription>
                        <StyledPadding>{locale.avgGasPriceDescription}</StyledPadding>
                    </StyledSectionDescription>
                </StyledGasAverageTitleContainer>
                <StyledDropdownControls>
                    <Dropdown align="end" className="dropdown-controls">
                            <Dropdown.Toggle 
                                size={width <= 768 ? 'sm' : undefined} 
                                variant={colorModePref === 'dark' ? 'dark' : 'secondary'}
                            >
                                {locale[chartType]}
                            </Dropdown.Toggle>
                            <Dropdown.Menu variant={colorModePref === 'dark' ? 'dark' : 'secondary'}>
                                <Dropdown.Item onClick={() => setChartType('fast')}>{locale.fast}</Dropdown.Item>
                                <Dropdown.Item onClick={() => setChartType('rapid')}>{locale.rapid}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                </StyledDropdownControls>
            </StyledGasAverageHeader>

            <StyledChartWrapper ref={ref}>
                <StyledChart>
                    <svg width={width} height={height}>
                        <Group left={margin.left} className="shapes">
                            <HeatmapRect
                                key="shapes"
                                data={binData}
                                xScale={(d) => xScale(d) ?? 0}
                                yScale={(d) => yScale(d) ?? 0}
                                colorScale={rectColorScale}
                                opacityScale={opacityScale}
                                binWidth={binWidth}
                                binHeight={binHeight}
                                gap={2}
                            >
                                {(heatmap) =>
                                    heatmap.map((heatmapBins) =>
                                        heatmapBins.map((bin) => (
                                            <rect
                                                key={`heatmap-rect-${bin.row}-${bin.column}`}
                                                className={`visx-heatmap-rect-${bin.x},${bin.y}`}
                                                width={bin.width}
                                                height={bin.height}
                                                x={bin.x}
                                                y={bin.y}
                                                /* @ts-ignore */
                                                fill={rectColorScale(chartType === 'fast' ? bin.bin.fast : bin.bin.rapid)}
                                                fillOpacity={bin.opacity}
                                            />
                                        )),
                                    )
                                }
                            </HeatmapRect>
                            <HeatmapRect
                                key="label"
                                data={binData}
                                xScale={(d) => xScale(d) ?? 0}
                                yScale={(d) => yScale(d) ?? 0}
                                colorScale={rectColorScale}
                                opacityScale={opacityScale}
                                binWidth={binWidth}
                                binHeight={binHeight}
                                gap={2}
                            >
                                {(heatmap) =>
                                    heatmap.map((heatmapBins) =>
                                        heatmapBins.map((bin) => (
                                            <text 
                                                className="bin-label"
                                                textAnchor="middle"
                                                alignmentBaseline="middle"
                                                dominantBaseline="middle"
                                                key={`heatmap-rect-${bin.row}-${bin.column}`}
                                                x={bin.x + bin.width / 2}
                                                y={bin.y + bin.height / 2 + 1}
                                            >
                                                {/* @ts-ignore */}
                                                {chartType === 'fast' ? bin.bin.fast : bin.bin.rapid}
                                            </text>
                                        )),
                                    )
                                }
                            </HeatmapRect>
                            <AxisLeft
                                top={margin.top + 5}
                                hideAxisLine
                                hideTicks
                                numTicks={24}
                                left={-12}
                                // @ts-ignore
                                scale={yScale}
                                // tickValues={['00:00', '23:00']}
                                // @ts-ignore
                                tickFormat={(val) => formatHour(val)}
                                tickLabelProps={() => ({
                                    fill: labelColor,
                                    fontSize: 11,
                                    textAnchor: 'middle',
                                })}
                            />
                            <AxisBottom
                                hideAxisLine
                                top={yMax + margin.top + 16}
                                // @ts-ignore
                                scale={bottomAxisScale}
                                stroke={labelColor}
                                tickStroke={labelColor}
                                // tickValues={}
                                numTicks={windowWidth < 600 ? 4 : 7}
                                tickFormat={windowWidth < 850 ? timeFormat("%m-%d %a") : timeFormat("%m-%d %A")}
                                tickLabelProps={() => ({
                                    fill: labelColor,
                                    fontSize: 11,
                                    textAnchor: 'middle',
                                })}
                            />
                            <Bar
                                x={0}
                                y={26}
                                width={xMax}
                                height={yMax}
                                fill="transparent"
                                rx={14}
                                // @ts-ignore
                                onMouseMove={handleTooltip}
                                // @ts-ignore
                                onMouseLeave={() => hideTooltip()}
                            />
                        </Group>
                    </svg>
                    {/* @ts-ignore */}
                    {tooltipOpen && !!tooltipData?.future && (
                        <TooltipWithBounds
                            key={Math.random()}
                            top={tooltipTop || 0}
                            left={tooltipLeft || 0}
                            style={tooltipStyles}
                        >
                            <StyledInnerTooltip>
                                <div className="title">
                                    {/* @ts-ignore */}
                                    {timeFormat("%Y-%m-%d  %H:%M")(tooltipData?.time)}
                                    
                                </div>
                                <div className="gas">
                                    <span>
                                        {/* @ts-ignore */}
                                        <span style={{background: rectColorScale(tooltipData?.count)}} className="circle"></span>
                                        <span>Gas Price:</span> 
                                    </span>
                                    {/* @ts-ignore */}
                                    <span className="price">{tooltipData?.count}</span>
                                </div>
                            </StyledInnerTooltip>
                        </TooltipWithBounds>
                    )}
                    {/* @ts-ignore */}
                    {tooltipOpen && !tooltipData?.future && (
                        <TooltipWithBounds
                            key={Math.random()}
                            top={tooltipTop || 0}
                            left={tooltipLeft || 0}
                            style={tooltipStyles}
                        >
                            <StyledInnerTooltip>
                                <div className="title no-data">
                                    {locale.btd}
                                </div>
                            </StyledInnerTooltip>
                        </TooltipWithBounds>
                    )}
                </StyledChart>
            </StyledChartWrapper>
        </StyledSection>
    );
}

const StyledInnerTooltip = styled.div`
    .title {
        margin-bottom: 10px;

        &.no-data {
            margin-bottom: 0px;
        }
    }
    .circle {
        display: inline-block;
        height: 8px;
        width: 8px;
        border-radius: 4px;
        margin-right: 5px;
    }
    .gas {
        display: flex;
        flex-direction: row;
        flex-wrap: no-wrap;
        justify-content: space-between;
        align-items: center;
    }
    .price {
        margin-left: 5px;
    }
`;

const StyledChart = styled.div`
    .bin-label {
        mix-blend-mode: multiply;
        fill: ${color.font};
        font-size: 14px;
        font-weight: 400;
        pointer-events: none;

        @media only screen and (max-width: ${WIDTH_MOBILE}) {
            font-size: 12px;
        }
        @media only screen and (max-width: 460px) {
            font-size: 11px;
        }
    }

    .mode-dark & {
        .shapes {
            background: rgba(255,255,255,0.5);
        }
    }
`;

const StyledGasAverageHeader = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
    align-items: flex-start;
`;
const StyledGasAverageTitleContainer = styled.div`
    flex: 1 1 auto;
`;

const StyledPadding = styled.div`
    padding: 0px 16px;
    @media only screen and (max-width: ${WIDTH_TABLET}) {
        padding: 0 16px 0 0;
        max-width: 344px;
    }
    @media only screen and (max-width: 420px) {
        display: none;
    }
`;