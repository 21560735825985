import React, { useEffect, useState, useRef, useCallback } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { useTooltip, TooltipWithBounds, defaultStyles } from '@vx/tooltip';
import { localPoint } from '@vx/event';
import { LinePath } from '@visx/shape';
import { Line, Bar } from '@vx/shape';
import { curveMonotoneX } from '@visx/curve';
import { scaleTime, scaleLinear } from '@visx/scale';
import { extent, bisector } from 'd3-array';
import { timeFormat } from 'd3-time-format';
import { AxisBottom, AxisLeft } from '@vx/axis';
import { MarkerCircle } from '@visx/marker';
import { Group } from '@vx/group';
import styled from '@emotion/styled';
import { TildeIcon } from '../asset';
import {
    color, 
    useLocale,
    StyledSection,
    StyledSectionTitle,
    StyledSectionDescription,
    StyledChartWrapper,
    StyledDropdownControls,
    useWindowSize,
    WIDTH_TABLET
} from '../util';
import { 
    useAppDispatch,
    useAppSelector, 
    selectHistoricalGas,
    updateHistoricalGasType,
} from '../redux';

type GasHistoricalType = {
    readonly colorModePref: string;
}

type GasHistoricalDataType = {
    readonly rapid: number;
    readonly fast: number;
    readonly txKnown: number;
    readonly txEst: number;
    readonly blocknumber: string;
    readonly time: number | Date;
}

export function GasHistorical({colorModePref}: GasHistoricalType) {
    const {
        payloadType: chartType,
        data
    } = useAppSelector(selectHistoricalGas);
    const dispatch = useAppDispatch();
    const locale = useLocale();
    const tooltipLineColor = colorModePref === 'dark' ? color.backgroundDark : color.background;
    const circleColor = colorModePref === 'dark' ? 'rgba(255,255,255,0.8)' : 'rgba(51, 51, 51,0.8)';
    const labelColor = colorModePref === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(51, 51, 51,0.5)';
    const lineColor = colorModePref === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(51, 51, 51,0.05)';
    const lineRapidColor = color.green;
    const lineFastColor = colorModePref === 'dark' ? color.orangeDark : color.orange;
    const [width, setWidth] = useState(0);
    const ref = useRef<HTMLHeadingElement>(null);
    const [windowWidth,] = useWindowSize();
    const height = width <= 480 ? 240 : 260;
    const margin = {
        left: 32,
        right: 10,
        top: 32,
        bottom: 20
    }
    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    useEffect(() => {
        setWidth(ref.current ? ref.current.offsetWidth : 0);
    }, [windowWidth]);

    const extentMixedWithPadding = (data: any, getOne: any, getTwo: any) => {
        const paddingPerc = width <= 480 ? 0.55 : 0.2;
        // @ts-expect-error
        const extentOne = extent(data, getOne) as [number, number];
        // @ts-expect-error
        const extentTwo = extent(data, getTwo) as [number, number];
        // find greatest min/max
        const extentCalc = [
            extentOne[0] <= extentTwo[0] ? extentOne[0] : extentTwo[0],
            extentOne[1] > extentTwo[1] ? extentOne[1] : extentTwo[1]
        ];
        // adding padding
        return [
            extentCalc[0] - ((extentCalc[1] - extentCalc[0]) * paddingPerc),
            extentCalc[1] + ((extentCalc[1] - extentCalc[0]) * paddingPerc)
        ];
    }

    // data accessors
    // const formatDate = timeFormat("%b %d, '%y");
    const formatDate = useCallback(() => {
        switch (chartType) {
            case 'hour_1':
                return timeFormat("%a %H:%M"); 
            case 'day_1':
                return timeFormat("%b %d, '%y");
            case 'minute_1':
            case 'minute_10':
            default:
                return timeFormat("%H:%M"); 
        };
    }, [chartType]);
    const getX = useCallback((d: GasHistoricalDataType): number | Date => {
        return chartType === 'block' ? parseInt(d?.blocknumber) : new Date(d?.time);
    }, [chartType]);
    const getYRapid = (d: GasHistoricalDataType): number => d?.rapid;
    const getYFast = (d: GasHistoricalDataType): number => d?.fast;
    const bisectX = bisector((s: GasHistoricalDataType) => chartType === 'block' ? parseInt(s?.blocknumber) : new Date(s?.time)).left;

    // scales
    const xScale = chartType === 'block' ? scaleLinear<number>({
        range: [0, xMax - 20],
        // @ts-expect-error
        domain: extent(data, getX) as [number, number],
    }) : scaleTime<number>({ 
        range: [0, xMax - 20],
        // @ts-expect-error
        domain: extent(data, getX) as [number, number],
    });
    
    const yScale = scaleLinear<number>({
        range: [yMax, 0],
        domain: extentMixedWithPadding(data, getYRapid, getYFast) as [number, number],
    });
    
    // tooltip
    const tooltipStyles = {
        ...defaultStyles,
        background: colorModePref === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(51, 51, 51,0.05)',
        border: `1px solid ${lineColor}`,
        maxWidth: `${windowWidth < 640 ? 150 : 300}px`,
        color: colorModePref === 'dark' ? color.fontDark : color.font,
        boxShadow: 'none',
        padding: '8px 10px',
        lineHeight: '20px'
    };
    const {
        tooltipData,
        tooltipLeft = 0,
        tooltipTop = 0,
        tooltipOpen,
        showTooltip,
        hideTooltip,
    } = useTooltip();
    const handleTooltip = useCallback((event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
        const { x } = localPoint(event) || { x: 0 };
        
        const x0 = xScale.invert(x - margin.left);
        const workingState = [...data].reverse(); 
        // @ts-expect-error
        const index = bisectX(workingState, x0, 1); 
        const d0 = workingState[index - 1];
        const d1 = workingState[index];

        let d = d0;
        // @ts-expect-error
        if (d1 && getX(d1)) {
            // @ts-expect-error
          d = x0.valueOf() - getX(d0).valueOf() > getX(d1).valueOf() - x0.valueOf() ? d1 : d0;
        }

        showTooltip({
            tooltipLeft: x - margin.left,
            // @ts-expect-error
            tooltipTop: yScale(getYRapid(d)),
            tooltipData: {
                // @ts-expect-error
                circleLeft: xScale(getX(d)),
                // @ts-expect-error
                circleFastTop: yScale(getYFast(d)),
                // @ts-expect-error
                blockOrDate: chartType === 'block' ? d?.blocknumber : formatDate()(new Date(d?.time)),
                rapid: d?.rapid,
                fast: d?.fast
            }
        });
    }, [
        showTooltip, 
        xScale, 
        yScale, 
        margin.left, 
        data, 
        bisectX,
        chartType,
        getX,
        formatDate
    ]);

    const optionsMap = {
        block: locale.histSelectBlock,
        minute_1: locale.histSelectMinuteOne,
        minute_10: locale.histSelectMinuteTen,
        hour_1: locale.histSelectHourOne,
        day_1: locale.histSelectDayOne,
    };

    return (
        <StyledSection>
            <StyledGasHistoricalHeader>
                <StyledGasHistoricalTitleContainer>
                    <StyledSectionTitle>{locale.histGasPrice}</StyledSectionTitle>
                    <StyledSectionDescription>
                        <StyledPadding>{locale.histGasPriceDescription}</StyledPadding>
                    </StyledSectionDescription>
                </StyledGasHistoricalTitleContainer>
                <StyledDropdownControls>
                    <Dropdown align="end" className="dropdown-controls">
                        <Dropdown.Toggle 
                            size={width <= 768 ? 'sm' : undefined} 
                            variant={colorModePref === 'dark' ? 'dark' : 'secondary'}
                        >
                            {optionsMap[chartType]}
                        </Dropdown.Toggle>
                        <Dropdown.Menu variant={colorModePref === 'dark' ? 'dark' : 'secondary'}>
                            <Dropdown.Item onClick={() => dispatch(updateHistoricalGasType('block'))}>{optionsMap.block}</Dropdown.Item>
                            <Dropdown.Item onClick={() => dispatch(updateHistoricalGasType('minute_1'))}>{optionsMap.minute_1}</Dropdown.Item>
                            <Dropdown.Item onClick={() => dispatch(updateHistoricalGasType('minute_10'))}>{optionsMap.minute_10}</Dropdown.Item>
                            <Dropdown.Item onClick={() => dispatch(updateHistoricalGasType('hour_1'))}>{optionsMap.hour_1}</Dropdown.Item>
                            <Dropdown.Item onClick={() => dispatch(updateHistoricalGasType('day_1'))}>{optionsMap.day_1}</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </StyledDropdownControls>
            </StyledGasHistoricalHeader>
            
            <StyledChartWrapper ref={ref}>
                <svg width={width} height={height}>
                    <MarkerCircle id="marker-circle" fill="#fff" size={2} refX={2} />
                    <Group left={margin.left} top={margin.top}>
                        <Line
                            from={{ x: 0, y: 0 }}
                            to={{ x: xMax, y: 0 }}
                            stroke={lineColor}
                            strokeWidth={2}
                            pointerEvents="none"
                            strokeDasharray="5,4"
                        />
                        <LinePath<GasHistoricalDataType>
                            curve={curveMonotoneX}
                            // @ts-expect-error
                            data={data}
                            x={(d) => xScale(getX(d)) ?? 0}
                            y={(d) => yScale(getYFast(d)) ?? 0}
                            stroke={lineFastColor}
                            strokeWidth={2}
                            strokeOpacity={1}
                            shapeRendering="geometricPrecision"
                            // markerMid="url(#marker-circle)"
                            // markerStart="url(#marker-circle)"
                            // markerEnd="url(#marker-circle)"
                        />
                        <LinePath<GasHistoricalDataType>
                            curve={curveMonotoneX}
                            // @ts-expect-error
                            data={data}
                            x={(d) => xScale(getX(d)) ?? 0}
                            y={(d) => yScale(getYRapid(d)) ?? 0}
                            stroke={lineRapidColor}
                            strokeWidth={2}
                            strokeOpacity={1}
                            shapeRendering="geometricPrecision"
                            // markerMid="url(#marker-circle)"
                            // markerStart="url(#marker-circle)"
                            // markerEnd="url(#marker-circle)"
                        />
                        <Bar
                            x={0}
                            y={0}
                            width={xMax}
                            height={yMax}
                            fill="transparent"
                            rx={14}
                            // @ts-ignore
                            onTouchStart={handleTooltip}
                            // @ts-ignore
                            onTouchMove={handleTooltip}
                            // @ts-ignore
                            onMouseMove={handleTooltip}
                            // @ts-ignore
                            onMouseLeave={() => hideTooltip()}
                        />
                        <AxisLeft
                            hideAxisLine
                            hideTicks
                            numTicks={3}
                            left={-8}
                            // @ts-ignore
                            scale={yScale}
                            // tickFormat={formatDate}
                            tickLabelProps={() => ({
                                fill: labelColor,
                                fontSize: 11,
                                textAnchor: 'middle',
                            })}
                        />
                        <AxisBottom
                            top={yMax}
                            // @ts-ignore
                            scale={xScale}
                            stroke={labelColor}
                            tickStroke={labelColor}
                            // tickValues={}
                            numTicks={width <= 820 ? (width <= 480 ? 3 : 5) : undefined}
                            tickFormat={chartType === 'block' ? (d) => d : formatDate()}
                            tickLabelProps={() => ({
                                fill: labelColor,
                                fontSize: 11,
                                textAnchor: 'middle',
                            })}
                        />
                        {tooltipOpen && (
                            <g>
                                <Line
                                    from={{ x: tooltipLeft, y: 0 }}
                                    to={{ x: tooltipLeft, y: yMax }}
                                    stroke={tooltipLineColor}
                                    strokeWidth={2}
                                    pointerEvents="none"
                                    strokeDasharray="5,4"
                                />
                                <circle
                                    // @ts-expect-error
                                    cx={tooltipData?.circleLeft ?? 0}
                                    cy={tooltipTop}
                                    r={4}
                                    fill={lineRapidColor}
                                    fillOpacity={0.5}
                                    stroke={circleColor}
                                    strokeOpacity={1}
                                    strokeWidth={2}
                                    pointerEvents="none"
                                />
                                <circle
                                    // @ts-expect-error
                                    cx={tooltipData?.circleLeft ?? 0}
                                    // @ts-expect-error
                                    cy={tooltipData?.circleFastTop ?? 0}
                                    r={4}
                                    fill={lineFastColor}
                                    fillOpacity={0.5}
                                    stroke={circleColor}
                                    strokeOpacity={1}
                                    strokeWidth={2}
                                    pointerEvents="none"
                                />
                            </g>
                        )}
                    </Group>
                </svg>
                {tooltipOpen && (
                    <TooltipWithBounds
                        key={Math.random()}
                        top={35}
                        left={(tooltipLeft || 0) + 30}
                        style={tooltipStyles}
                    >
                        <StyledInnerTooltip>
                            <div className="title">
                                {/* @ts-ignore */}
                                {tooltipData?.blockOrDate}
                            </div>
                            <div className="gas">
                                <div className="vert">
                                    <span className="circ rapid"></span>
                                    {locale.rapid}:
                                </div> 
                                {/* @ts-ignore */}
                                <div className="val">{tooltipData?.rapid}</div>
                            </div>
                            <div className="gas">
                                <div className="vert">
                                    <span className="circ fast"></span>
                                    {locale.fast}:
                                </div> 
                                {/* @ts-ignore */}
                                <div className="val">{tooltipData?.fast}</div>
                            </div>
                        </StyledInnerTooltip>
                    </TooltipWithBounds>
                )}
            </StyledChartWrapper>
            <StyledLegend>
                <StyledLegendItem className="rapid">
                    <TildeIcon />
                    <span>{locale.rapid}</span>
                </StyledLegendItem>
                <StyledLegendItem className="fast">
                    <TildeIcon />
                    <span>{locale.fast}</span>
                </StyledLegendItem>
            </StyledLegend>
        </StyledSection>
        
    );
}

const StyledPadding = styled.div`
    padding: 0px 16px;
    @media only screen and (max-width: ${WIDTH_TABLET}) {
        padding: 0 16px 0 0;
        max-width: 344px;
    }
    @media only screen and (max-width: 420px) {
        display: none;
    }
`;

const StyledLegend = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: -10px;
`;

const StyledLegendItem = styled.div`
    margin: 20px 5px 0;
    span {
        opacity: 0.5;
        font-size: 12px;
        margin: 0 5px;
    }
    svg {
        max-width: 15px;
        max-height: 15px;
    }
    &.fast svg {
        fill: ${color.orange};
    }
    &.rapid svg {
        fill: ${color.green};
    }

    .mode-dark & {
        &.fast svg {
            fill: ${color.orangeDark};
        }
    }
`;

const StyledGasHistoricalHeader = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
    align-items: flex-start;
`;

const StyledGasHistoricalTitleContainer = styled.div`
    flex: 1 1 auto;
`;

const StyledInnerTooltip = styled.div`
    .title {
        margin-bottom: 10px;
    }
    .gas {
        display: flex;
        flex-direction: row;
        flex-wrap: no-wrap;
        justify-content: space-between;
        align-items: center;

        .vert {
            display: flex;
            flex-direction: row;
            flex-wrap: no-wrap;
            justify-content: space-between;
            align-items: center;
        }

        .circ {
            display: block;
            height: 8px;
            width: 8px;
            border-radius: 4px;
            background: white;
            margin-right: 5px;

            &.rapid {
                background: ${color.green};
            }
            &.fast {
                background: ${color.orange};
            }
        }

        .val {
            margin-left: 10px;
        }
    }

    .mode-dark & {
        .gas .circ.fast {
            background: ${color.orangeDark};
        }
    }
`;