import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { 
    color, 
    useLocale,
    convertHexToRGBA,
    StyledSectionTitle,
    StyledSectionDescription,
    WIDTH_TABLET,
    WIDTH_MOBILE,
    StyledLoadingBars
} from '../util';
import { 
    LogoGasSlow,
    LogoGasStandard,
    LogoGasFast,
    LogoGasRapid
  } from '../asset';
import { 
    useAppSelector, 
    selectGasStats,
    selectEthPriceUsd
} from '../redux';

export function GasStats() {
    const locale = useLocale();
    const transferCostGas = 21000;
    function calculateTransferCost(gasPriceGwei: number, ethPrice: number) { 
        return (((transferCostGas * gasPriceGwei) / 1000000000) * ethPrice).toFixed(2);
    }

    const {
        gasRapid,
        gasFast,
        gasStandard,
        gasSlow
    } = useAppSelector(selectGasStats);
    const ethPrice = useAppSelector(selectEthPriceUsd);
    const [txCostRapid, setTxCostRapid] = useState(calculateTransferCost(gasRapid, ethPrice));
    const [txCostFast, setTxCostFast] = useState(calculateTransferCost(gasFast, ethPrice));
    const [txCostStandard, setTxCostStandard] = useState(calculateTransferCost(gasStandard, ethPrice));
    const [txCostSlow, setTxCostSlow] = useState(calculateTransferCost(gasSlow, ethPrice));

    useEffect(() => {
        setTxCostRapid(calculateTransferCost(gasRapid, ethPrice));
        setTxCostFast(calculateTransferCost(gasFast, ethPrice));
        setTxCostStandard(calculateTransferCost(gasStandard, ethPrice));
        setTxCostSlow(calculateTransferCost(gasSlow, ethPrice));

        if (gasRapid !== 0 && gasSlow !== 0) {
            document.title = `${gasRapid}-${gasSlow} ${locale.title}`;
        }
    }, [
        gasRapid,
        gasFast,
        gasStandard,
        gasSlow, 
        ethPrice,
        locale.title
    ]);

    return (
        <>
            <StyledSectionTitle className="pad-top tablet-except">{locale.gasStatTitle}</StyledSectionTitle> 
            <StyledSectionDescription className="tablet-except">{locale.gasStatDescription}</StyledSectionDescription>

            <GasPriceWrapper>
                <GasPriceList>
                    <GasPrice className="rapid">
                        <div className="label">
                            <span><LogoGasRapid /></span>
                            <span>{locale.rapid}</span>
                        </div>
                        <div className="price">
                            <StyledLoadingBarsWrap className={!gasFast ? 'loading' : ''} />
                            <strong className={!gasFast ? 'loading' : ''}>{gasRapid}</strong>
                        </div>
                        <div className="timecost">
                            {locale.currency}{txCostRapid} <span className="hideMobile">| </span><span className="displayMobile">15 {locale.seconds}</span>
                        </div>
                    </GasPrice>
                    <GasPrice className="fast">
                        <div className="label">
                            <span><LogoGasFast /></span>
                            <span>{locale.fast}</span>
                        </div>
                        <div className="price">
                            <StyledLoadingBarsWrap className={!gasFast ? 'loading' : ''} />
                            <strong className={!gasFast ? 'loading' : ''}>{gasFast}</strong>
                        </div>
                        <div className="timecost">
                            {locale.currency}{txCostFast} <span className="hideMobile">| </span><span className="displayMobile">1 {locale.minute}</span>
                        </div>
                    </GasPrice>
                    <GasPrice className="standard">
                        <div className="label">
                            <span><LogoGasStandard /></span>
                            <span>{locale.standard}</span>
                        </div>
                        <div className="price">
                            <StyledLoadingBarsWrap className={!gasFast ? 'loading' : ''} />
                            <strong className={!gasFast ? 'loading' : ''}>{gasStandard}</strong>
                        </div>
                        <div className="timecost">
                            {locale.currency}{txCostStandard} <span className="hideMobile">| </span><span className="displayMobile">3 {locale.minutes}</span>
                        </div>
                    </GasPrice>
                    <GasPrice className="slow">
                        <div className="label">
                            <span><LogoGasSlow /></span>
                            <span>{locale.slow}</span>
                        </div>
                        <div className="price">
                            <StyledLoadingBarsWrap className={!gasFast ? 'loading' : ''} />
                            <strong className={!gasFast ? 'loading' : ''}>{gasSlow}</strong>
                        </div>
                        <div className="timecost">
                            {locale.currency}{txCostSlow} <span className="hideMobile">| </span><span className="displayMobile">&#62;10 {locale.minutes}</span>
                        </div>
                    </GasPrice>
                </GasPriceList>
            </GasPriceWrapper>
        </>
    );
}

const StyledLoadingBarsWrap = styled(StyledLoadingBars)`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -2px;
    transform: translateX(-50%) translateY(-50%);

    .rapid & rect {
        fill: ${color.green};
        .mode-dark & {
            fill: ${color.greenDark};
        }
    }

    .fast & rect {
        fill: ${color.orange};
        .mode-dark & {
            fill: ${color.orangeDark};
        }
    }

    .standard & rect {
        fill: ${color.blue};
        .mode-dark & {
            fill: ${color.blueDark};
        }
    }

    .slow & rect {
        fill: ${color.purple};
        .mode-dark & {
            fill: ${color.purpleDark};
        }
    }

    @media only screen and (max-width: ${WIDTH_TABLET}) {
        transform: translateY(-50%);
        left: auto;
        right: 0px;
    }
`;

const GasPriceWrapper = styled.div`
    margin: 0 auto;
    max-width: 986px;
    padding-bottom: 24px;

    @media only screen and (max-width: ${WIDTH_MOBILE}) {
        padding-bottom: 20px;
    }
`;

const GasPriceList = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: no-wrap;
    justify-content: space-between;
    align-items: center;
    margin: 26px 0px 0px;

    @media only screen and (max-width: ${WIDTH_TABLET}) {
        flex-direction: column;
    }
`;

const GasPrice = styled.div`
    height: 176px;
    width: 22.5%;
    background: ${color.sectionBackground};
    border-radius: 22px;
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: ${WIDTH_TABLET}) {
        height: 80px;
        width: 80%;
        margin-bottom: 12px;
        flex-direction: row;

        &:last-child {
            margin-bottom: 2px;
        }
    }

    @media only screen and (max-width: ${WIDTH_MOBILE}) {
        width: 100%;
    }

    

    .label {
        font-size: 24px;
        display: flex;
        flex-direction: row;
        flex-wrap: no-wrap;
        justify-content: flex-start;
        align-items: center;
        margin: 26px 0px -7px;
        max-height: 34px;

        svg {
            width: 36px;
        }

        @media only screen and (max-width: ${WIDTH_TABLET}) {
            margin: 2px 20px 0 20px;
        }
        @media only screen and (max-width: ${WIDTH_MOBILE}) {
            font-size: 18px;
            margin: 2px 10px 0 15px;
        }
    }

    .timecost {
        font-size: 14px;
        margin: 0 0 26px 0;
        opacity: 0.6;

        @media only screen and (max-width: ${WIDTH_TABLET}) {
            margin: 0 20px 0 20px;
            text-align: right;
            min-width: 88px;
            .hideMobile {
                display: none;
            }
            .displayMobile {
                display: block;
            }
        }
        @media only screen and (max-width: ${WIDTH_TABLET}) {
            margin: 0 15px 0 10px;
        }
    }

    .price {
        position: relative;
        font-size: 50px;
        margin-bottom: -7px;
        // text-shadow: 0px 0px 5px rgba(255, 255, 255, 0.15);

        strong {
            opacity: 1;
            &.loading {
                opacity: 0;
            }
        }
        

        @media only screen and (max-width: ${WIDTH_TABLET}) {
            font-size: 38px;
            flex: 1 1;
            display: flex;
            justify-content: flex-end;
            margin-bottom: 0px;
        }
        @media only screen and (max-width: ${WIDTH_MOBILE}) {
            font-size: 30px;
        }
    }
    
    &.rapid {
        border: 1px solid ${convertHexToRGBA(color.green, 0.2)};
        .label svg {
            fill: ${color.green};
        }

        .mode-dark & {
            border: 1px solid ${convertHexToRGBA(color.greenDark, 0.2)};
            .label svg {
                fill: ${color.green};
                stroke-width: 18px;
                stroke: ${color.sectionBackgroundDark};
            }
        }

        .price {
            color: ${color.green};
            .mode-dark & {
                color: ${color.green};
            }
        }
    }
    &.fast {
        border: 1px solid ${convertHexToRGBA(color.orange, 0.8)};
        .label svg {
            fill: ${color.orange};
        }

        .mode-dark & {
            border: 1px solid ${convertHexToRGBA(color.orangeDark, 0.8)};
            .label svg {
                fill: ${color.orangeDark};
            }
        }

        .price {
            color: ${color.orange};
            .mode-dark & {
                color: ${color.orangeDark};
            }
        }
    }
    &.standard {
        border: 1px solid ${convertHexToRGBA(color.blue, 0.2)};
        .label svg {
            fill: ${color.blue};
        }

        .mode-dark & {
            border: 1px solid ${convertHexToRGBA(color.blueDark, 0.2)};
            .label svg {
                fill: ${color.blueDark};
            }
        }

        .price {
            color: ${color.blue};
            .mode-dark & {
                color: ${color.blueDark};
            }
        }
    }
    &.slow {
        border: 1px solid ${convertHexToRGBA(color.purple, 0.2)};
        .label svg {
            fill: ${color.purple};
        }

        .mode-dark & {
            border: 1px solid ${convertHexToRGBA(color.purpleDark, 0.2)};
            .label svg {
                fill: ${color.purpleDark};
            }
        }

        .price {
            color: ${color.purple};
            .mode-dark & {
                color: ${color.purpleDark};
            }
        }
    }

    .mode-dark & {
        background: ${color.sectionBackgroundDark};
    }
`;