import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

// Types
type EthPriceState = {
    readonly usd: number;
};

// Initial State
const initialState = { usd: 0 } as EthPriceState;

export const sliceEthPrice = createSlice({
    name: 'ethPrice',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        update: (_state, action: PayloadAction<EthPriceState>) => {
            return action.payload;
        },
    },
}); 

export const { update: updateEthPrice } = sliceEthPrice.actions;
export const selectEthPriceUsd = (state: RootState) => state.ethPrice.usd;
export const { reducer: reducerEthPrice } = sliceEthPrice;

