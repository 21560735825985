import React from 'react';

export function LogoGasRapid() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1100 1100">
            <rect x="319.92" y="823.1" width="456.64" height="69.37" transform="translate(1096.49 1715.58) rotate(180)"/>
            <path d="M567.77,631.45l126.81,63-92-109.26a55.32,55.32,0,1,0-34.8,46.3Z"/>
            <path d="M891.44,433.84A369.21,369.21,0,0,0,812,316.06q-13.15-13.14-27.4-24.82l-2.14-2.14-.23.23a369.42,369.42,0,0,0-167.66-76.21C579.53,162.34,485.87,114.21,413,108,240.59,93.3,68.22,59.48,73,39,59.66,96.16,108,185,193,203c123.86,26.23,65,79,42,94,0,0-88.27,60.46-73-22,5-27,3.81-50.72-89-77,71.35,67.11-44.08,160.13,115.89,296.43a370.23,370.23,0,0,0,35.91,261.5h98.5l40.18-40.19L325,677.24l-40.83,40.83a298.77,298.77,0,0,1-34.48-140c0-5.59.17,6.42.47.88h67.15V524.53H258.12c12-49.61,36.54-112.94,72.05-151l46.62,46.62,38.5-38.5-44.55-44.55A297.33,297.33,0,0,1,521,279.15V343.2h54.45V278.85a297.46,297.46,0,0,1,156.78,60.5L690,381.62l38.5,38.5,43.89-43.89c33.94,37.31,57.52,99.33,69.3,147.43H781.83V578.1h68c.34,5.83.53-5.88.53,0a298.79,298.79,0,0,1-33.88,138.82L775.9,676.37l-38.5,38.49,41.06,41.07h96.73a371.32,371.32,0,0,0,16.25-322.09Z"/>
        </svg>
    );
}