import React from 'react';

export function LogoStab() {
    return (
        <svg width="371" height="327" viewBox="0 0 371 327" fill="none" xmlns="http://www.w3.org/2000/svg">
            <linearGradient id="gradient" x2="0%" y2="100%">
                <stop className="start" offset="0%"/>
                <stop className="end" offset="100%"/>
            </linearGradient>
            <path fill="url(#gradient)" d="M99.5 0.78125L0.28125 167.469L99.5 227L198.719 167.469L99.5 0.78125ZM0.28125 187.312L99.5 326.219L198.719 187.312L99.5 246.844L0.28125 187.312Z" />
            <path fill="url(#gradient)" d="M266.288 47.4293L254.681 59.0366L266.288 70.644L254.681 82.2513L266.288 93.8586L254.681 105.466L266.288 117.073L254.681 128.681L266.288 140.288L254.681 151.895L266.288 163.503L243.073 186.717L231.466 175.11L208.251 198.325L243.073 233.146L254.681 221.539L277.895 244.754L266.288 256.361L277.895 267.968L254.681 291.183L289.502 326.005L324.324 291.183L301.11 267.968L312.717 256.361L301.11 244.754L324.324 221.539L335.932 233.146L370.754 198.325L347.539 175.11L335.932 186.717L312.717 163.503L324.324 151.895L312.717 140.288L324.324 128.681L312.717 117.073L324.324 105.466L312.717 93.8586L324.324 82.2513L312.717 70.644L324.324 59.0366L312.717 47.4293L324.324 35.822L289.502 1L254.681 35.822L266.288 47.4293ZM312.717 47.4293L301.11 59.0366L312.717 70.644L301.11 82.2513L312.717 93.8586L301.11 105.466L312.717 117.073L301.11 128.681L312.717 140.288L301.11 151.895L312.717 163.503L289.502 186.717L301.11 198.325L312.717 186.717L324.324 198.325L335.932 186.717L347.539 198.325L324.324 221.539L312.717 209.932L289.502 233.146L301.11 244.754L289.502 256.361L301.11 267.968L289.502 279.576L301.11 291.183L289.502 302.79L277.895 291.183L289.502 279.576L277.895 267.968L289.502 256.361L277.895 244.754L289.502 233.146L266.288 209.932L254.681 221.539L231.466 198.325L243.073 186.717L254.681 198.325L266.288 186.717L277.895 198.325L289.502 186.717L266.288 163.503L277.895 151.895L266.288 140.288L277.895 128.681L266.288 117.073L277.895 105.466L266.288 93.8586L277.895 82.2513L266.288 70.644L277.895 59.0366L266.288 47.4293L289.502 24.2147L312.717 47.4293Z" />
        </svg>
    );
}