import React from 'react';

export function LogoTweet() {
    return (
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <linearGradient id="hover-gradient-light" gradientTransform="rotate(0)" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="#1da1f1" stopOpacity="0.7" />
                    <stop offset="100%" stopColor="#0060ff" stopOpacity="0.8" />
                </linearGradient>
                <linearGradient id="hover-gradient-dark" gradientTransform="rotate(-90)" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="#1da1f1" stopOpacity="0.8" />
                    <stop offset="100%" stopColor="#41ff58" stopOpacity="0.2" />
                </linearGradient>
                <linearGradient id="hover-gradient-stroke" gradientTransform="rotate(90)" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor="#1da1f1" stopOpacity="0.8" />
                    <stop offset="100%" stopColor="#41ff58" stopOpacity="0.2" />
                </linearGradient>
            </defs>
            <path className="bg" d="M18 33.814C26.7338 33.814 33.814 26.7338 33.814 18C33.814 9.2662 26.7338 2.18605 18 2.18605C9.2662 2.18605 2.18605 9.2662 2.18605 18C2.18605 26.7338 9.2662 33.814 18 33.814Z" fill="black" stroke="white" strokeWidth="2"/>
            <path className="birb" d="M15.1061 26.3419C22.1195 26.3419 25.9544 20.5302 25.9544 15.4935C25.9544 15.3274 25.9544 15.1614 25.9465 15.0033C26.6898 14.4656 27.3381 13.7935 27.8521 13.0265C27.1721 13.327 26.4368 13.5326 25.6619 13.6274C26.4526 13.153 27.0535 12.4098 27.3381 11.5163C26.6028 11.9512 25.7884 12.2674 24.9186 12.4414C24.2228 11.6981 23.2344 11.2395 22.1354 11.2395C20.0321 11.2395 18.3242 12.9474 18.3242 15.0507C18.3242 15.3512 18.3558 15.6437 18.427 15.9205C15.2563 15.7623 12.4493 14.2442 10.5674 11.9353C10.2433 12.4967 10.0535 13.153 10.0535 13.8488C10.0535 15.1693 10.7256 16.3395 11.7535 17.0195C11.1288 17.0037 10.5437 16.8298 10.0298 16.5451C10.0298 16.5609 10.0298 16.5767 10.0298 16.5926C10.0298 18.4428 11.3423 19.9767 13.0898 20.3326C12.7735 20.4195 12.4335 20.467 12.0856 20.467C11.8405 20.467 11.6033 20.4433 11.3661 20.3958C11.8484 21.914 13.2558 23.013 14.9242 23.0446C13.6195 24.0646 11.9749 24.6735 10.1879 24.6735C9.87954 24.6735 9.57908 24.6577 9.27861 24.6181C10.947 25.7093 12.9554 26.3419 15.1061 26.3419Z" fill="white"/>
        </svg>
    );
} 

// src:
/* <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M18 33.814C26.7338 33.814 33.814 26.7338 33.814 18C33.814 9.2662 26.7338 2.18605 18 2.18605C9.2662 2.18605 2.18605 9.2662 2.18605 18C2.18605 26.7338 9.2662 33.814 18 33.814Z" fill="black" stroke="white" stroke-width="4"/>
<path d="M15.1061 26.3419C22.1195 26.3419 25.9544 20.5302 25.9544 15.4935C25.9544 15.3274 25.9544 15.1614 25.9465 15.0033C26.6898 14.4656 27.3381 13.7935 27.8521 13.0265C27.1721 13.327 26.4368 13.5326 25.6619 13.6274C26.4526 13.153 27.0535 12.4098 27.3381 11.5163C26.6028 11.9512 25.7884 12.2674 24.9186 12.4414C24.2228 11.6981 23.2344 11.2395 22.1354 11.2395C20.0321 11.2395 18.3242 12.9474 18.3242 15.0507C18.3242 15.3512 18.3558 15.6437 18.427 15.9205C15.2563 15.7623 12.4493 14.2442 10.5674 11.9353C10.2433 12.4967 10.0535 13.153 10.0535 13.8488C10.0535 15.1693 10.7256 16.3395 11.7535 17.0195C11.1288 17.0037 10.5437 16.8298 10.0298 16.5451C10.0298 16.5609 10.0298 16.5767 10.0298 16.5926C10.0298 18.4428 11.3423 19.9767 13.0898 20.3326C12.7735 20.4195 12.4335 20.467 12.0856 20.467C11.8405 20.467 11.6033 20.4433 11.3661 20.3958C11.8484 21.914 13.2558 23.013 14.9242 23.0446C13.6195 24.0646 11.9749 24.6735 10.1879 24.6735C9.87954 24.6735 9.57908 24.6577 9.27861 24.6181C10.947 25.7093 12.9554 26.3419 15.1061 26.3419Z" fill="white"/>
</svg> */

