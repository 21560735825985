import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import styled from '@emotion/styled';
import { useTooltip, TooltipWithBounds, defaultStyles } from '@vx/tooltip';
import { localPoint } from '@vx/event';
import { AxisBottom, AxisLeft } from '@vx/axis';
import { AreaClosed, Line, Bar } from '@vx/shape';
import { curveMonotoneX } from '@vx/curve';
import { LinearGradient } from '@vx/gradient';
import { Group } from '@vx/group';
import { scaleLinear } from '@vx/scale';
import { extent, bisector } from 'd3-array';
import { useWindowSize } from '../util';

import { 
    color, 
    useLocale,
    StyledSectionTitle,
    StyledSectionDescription,
    StyledChartWrapper
} from '../util';
import { 
    useAppSelector, 
    selectGasCounts,
    selectGasStats
} from '../redux';

type GasCountsType = {
    readonly colorModePref: string;
    readonly className?: string;
}
type GasCountItemState = {
    readonly gasPrice: number;
    readonly count: number;
}

export function GasCounts({className, colorModePref}: GasCountsType) {
    const locale = useLocale();
    const {
        tooltipData,
        tooltipLeft,
        tooltipTop,
        tooltipOpen,
        showTooltip,
        hideTooltip,
    } = useTooltip();
    const tooltipLineColor = colorModePref === 'dark' ? color.backgroundDark : color.background;
    const labelColor = colorModePref === 'dark' ? 'rgba(255,255,255,0.5)' : 'rgba(51, 51, 51,0.5)';
    const lineColor = colorModePref === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(51, 51, 51,0.05)';
    const [width, setWidth] = useState(0);
    const { gasFast } = useAppSelector(selectGasStats);
    const state = useAppSelector(selectGasCounts);
    const [enableHighTxFilter, /*setEnableHighTxFilter*/] = useState(true);
    const [cumulativeState, setCumulativeState] = useState<GasCountItemState[]>(state);
    const [/*rawState*/, setRawState] = useState<GasCountItemState[]>(state);
    const [chartType, /*setChartType*/] = useState<'cumulative' |'instantaneous'>('cumulative');

    const ref = useRef<HTMLHeadingElement>(null);
    const [windowWidth,] = useWindowSize();
    
    useEffect(() => {
        if (!state || state.length === 0) return;
        setRawState(state);

        let cumulativeCount = 0;
        // count up state
        const cumulativeStateUnfiltered = [...state].reverse().map(({gasPrice, count}) => {
            cumulativeCount += count;
            return {
                gasPrice,
                count: cumulativeCount
            }
        });
        
        // get the average gas price
        const averageGasPrice = Math.ceil([...state].reduce((a, b) => ({
            gasPrice: a.gasPrice + b.gasPrice,
            count: 0
        })).gasPrice / state.length);
        
        // filter it out by a factor of 2.5
        const cumulativeStateFiltered = cumulativeStateUnfiltered.filter((s) => {
            return s.gasPrice < averageGasPrice * 2
        });
        
        // set either filtered or unfiltered depending on settings
        setCumulativeState(
            enableHighTxFilter ? cumulativeStateFiltered : cumulativeStateUnfiltered
        );
    }, [state, enableHighTxFilter]);

    useEffect(() => {
        setWidth(ref.current ? ref.current.offsetWidth : 0);
    }, [windowWidth]);

    const tooltipStylesFast = {
        ...defaultStyles,
        background: 'transparent',
        border: `1px solid ${lineColor}`,
        color: colorModePref === 'dark' ? color.orangeDark : color.orange,
        boxShadow: 'none'
    };

    const tooltipStyles = {
        ...defaultStyles,
        background: colorModePref === 'dark' ? 'rgba(255,255,255,0.1)' : 'rgba(51, 51, 51,0.05)',
        border: `1px solid ${lineColor}`,
        maxWidth: `${windowWidth < 640 ? 150 : 300}px`,
        color: colorModePref === 'dark' ? color.fontDark : color.font,
        boxShadow: 'none'
    };

    const height = 230;
    const margin = {
        left: 32,
        right: 15,
        top: 10,
        bottom: 20
    }

    // bounds
    const xMax = width - margin.left - margin.right;
    const yMax = height - margin.top - margin.bottom;

    const getGasPrice = (s: GasCountItemState) => s?.gasPrice ?? 0;
    const getTxCount = (s: GasCountItemState) => s?.count ?? 0;
    const bisectGasPrice = bisector((s: GasCountItemState) => s.gasPrice).left;

    const gasPriceScale = useMemo(
        () =>
            scaleLinear({
                range: [0, xMax],
                domain: extent(chartType === 'cumulative' ? cumulativeState : state, getGasPrice) as [number, number]
            }),
            [xMax, cumulativeState, chartType, state]
    );

    const txCountScale = useMemo(
        () =>
            scaleLinear({
                range: [yMax, 0],
                domain: extent(chartType === 'cumulative' ? cumulativeState : state, getTxCount) as [number, number],
                nice: true,
            }),
            [yMax, cumulativeState, chartType, state]
    );

    // const { containerRef, TooltipInPortal } = useTooltipInPortal({
    //     detectBounds: true,
    //     // when tooltip containers are scrolled, this will correctly update the Tooltip position
    //     scroll: true,
    //     debounce: 300,
    //   })

    const handleTooltip = useCallback((event: React.TouchEvent<SVGRectElement> | React.MouseEvent<SVGRectElement>) => {
        const { x } = localPoint(event) || { x: 0 };
        const x0 = gasPriceScale.invert(x - margin.left);
        const workingState = [...cumulativeState].reverse(); // TODO make this dynamic based on state type
        const index = bisectGasPrice(workingState, x0, 1); 
        const d0 = workingState[index - 1];
        const d1 = workingState[index];

        let d = d0;
        if (d1 && getGasPrice(d1)) {
          d = x0.valueOf() - getGasPrice(d0).valueOf() > getGasPrice(d1).valueOf() - x0.valueOf() ? d1 : d0;
        }

        showTooltip({
            tooltipLeft: x - margin.left,
            tooltipTop: txCountScale(getTxCount(d)),
            tooltipData: `${d.count} ${locale.txGasPriceAbove} ${d.gasPrice} ${locale.gwei}`
        });
    }, [
        showTooltip, 
        gasPriceScale, 
        txCountScale, 
        margin.left, 
        cumulativeState, 
        bisectGasPrice,
        locale.gwei,
        locale.txGasPriceAbove
    ]);

    return (
        <div className={className} ref={ref}>
            <StyledOverflow>
                <StyledSectionTitle>{locale.pendingTxTitle}</StyledSectionTitle>
                <StyledSectionDescription>
                    {locale.pendingTxDescription}
                </StyledSectionDescription>
            </StyledOverflow>
            <StyledChartWrapper>
                <StyledSvg width={width} height={height}>
                    <Group left={margin.left} top={margin.top}>
                        <LinearGradient 
                            id="area-gradient" 
                            from={color.blue} 
                            to={color.greenDark} 
                            fromOpacity={0.1}
                            toOpacity={colorModePref === 'dark' ? 0.8 : 0.9}
                            rotate={-90} 
                        />    
                        <AreaClosed
                            data={chartType === 'cumulative' ? cumulativeState : state}
                            // @ts-ignore
                            x={d => gasPriceScale(getGasPrice(d)) ?? 0}
                            // @ts-ignore
                            y={d => txCountScale(getTxCount(d)) ?? 0}
                            yScale={txCountScale}
                            strokeWidth={1}
                            stroke="url(#area-gradient)"
                            fill="url(#area-gradient)"
                            curve={curveMonotoneX}
                        />
                        <Bar
                            x={0}
                            y={0}
                            width={xMax}
                            height={yMax}
                            fill="transparent"
                            rx={14}
                            // @ts-ignore
                            onTouchStart={handleTooltip}
                            // @ts-ignore
                            onTouchMove={handleTooltip}
                            // @ts-ignore
                            onMouseMove={handleTooltip}
                            // @ts-ignore
                            onMouseLeave={() => hideTooltip()}
                        />
                        <AxisLeft
                            hideAxisLine
                            hideTicks
                            numTicks={2}
                            left={-8}
                            scale={txCountScale}
                            // tickFormat={formatDate}
                            tickLabelProps={() => ({
                                fill: labelColor,
                                fontSize: 11,
                                textAnchor: 'middle',
                            })}
                        />
                        <AxisBottom
                            top={yMax}
                            scale={gasPriceScale}
                            stroke={labelColor}
                            tickStroke={labelColor}
                            numTicks={width <= 480 ? 6 : undefined}
                            tickLabelProps={() => ({
                                fill: labelColor,
                                fontSize: 11,
                                textAnchor: 'middle',
                            })}
                        />
                        {!!gasPriceScale(gasFast) && (
                            <Line
                                from={{ x: gasPriceScale(gasFast), y: 0 }}
                                to={{ x: gasPriceScale(gasFast), y: yMax }}
                                stroke={colorModePref === 'dark' ? color.orangeDark : color.orange}
                                strokeWidth={1}
                                pointerEvents="none"
                            />
                        )}
                        <Line
                            from={{ x: 0, y: 0 }}
                            to={{ x: xMax, y: 0 }}
                            stroke={lineColor}
                            strokeWidth={2}
                            pointerEvents="none"
                            strokeDasharray="5,4"
                        />
                        {tooltipOpen && (
                            <g>
                                <Line
                                    from={{ x: tooltipLeft, y: 0 }}
                                    to={{ x: tooltipLeft, y: yMax }}
                                    stroke={tooltipLineColor}
                                    strokeWidth={2}
                                    pointerEvents="none"
                                    strokeDasharray="5,4"
                                />
                            </g>
                        )}
                    </Group>
                </StyledSvg>
                {!!gasPriceScale(gasFast) && (
                    <TooltipWithBounds
                        top={windowWidth > 640 ? 30 : 30}
                        left={(gasPriceScale(gasFast) || 0) + 30}
                        key={Math.random()}
                        style={tooltipStylesFast}
                        // stroke={colorModePref === 'dark' ? color.orangeDark : color.orange}
                    >
                        {locale.fast}
                    </TooltipWithBounds>
                )}
                {tooltipOpen && (
                    <TooltipWithBounds
                        // set this to random so it correctly updates with parent bounds
                        key={Math.random()}
                        top={(tooltipTop || 0) - 30}
                        left={(tooltipLeft || 0) + 30}
                        style={tooltipStyles}
                    >
                        {tooltipData}
                    </TooltipWithBounds>
                )}
            </StyledChartWrapper>
        </div>
    );
}

const StyledOverflow = styled.div`
    overflow: auto;
`;

const StyledSvg = styled.svg`
    height: 230px;
    margin: 18px auto -8px;
`;

