import styled from '@emotion/styled';
import {
    LoadingBars
} from '../asset';
import { color, convertHexToRGBA } from './';

export const WIDTH_TABLET = '768px';
export const WIDTH_MOBILE = '640px';

export const StyledLoadingBars = styled(LoadingBars)`
    height: 30px;
    width: 24px;
    display: none;

    &.loading {
        display: inline-block;
    }
`;

export const StyledChartWrapper = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
`;

export const StyledCenter = styled.div`
    width: 100%;
    height: 100%;
    max-width: 1200px;
    padding: 0 15px;
    margin: 0 auto 16px auto;
`;

export const StyledSection = styled.div`
    padding: 30px;
    margin-top: 15px;
    border-radius: 6px;

    background: ${color.sectionBackground};

    .mode-dark & {
        background: ${color.sectionBackgroundDark};
    }
`;

export const StyledSectionTitle = styled.div`
    text-align: center;
    font-size: 22px;
    display: block;

    &.pad-top {
        margin-top: 40px;
    }

    @media only screen and (max-width: ${WIDTH_TABLET}) {
        text-align: left;
        font-size: 16px;

        &.tablet-except {
            text-align: center;
        }
    }

    @media only screen and (max-width: ${WIDTH_MOBILE}) {
        &.tablet-except {
            text-align: left;
        }
    }
`;

export const StyledSectionDescription = styled.div`
    margin-top: 8px;
    text-align: center;
    opacity: 0.5;
    font-size: 14px;
    display: block;

    @media only screen and (max-width: ${WIDTH_TABLET}) {
        text-align: left;
        font-size: 14px;

        &.tablet-except {
            text-align: center;
        }
    }

    @media only screen and (max-width: ${WIDTH_MOBILE}) {
        &.tablet-except {
            text-align: left;
        }
    }
`;

export const StyledColumnWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: stretch;

    @media only screen and (max-width: ${WIDTH_MOBILE}) {
        flex-direction: column;
    }
`;

export const StyledColumn = styled.div`
    padding: 30px;
    order: 0;
    background: ${color.sectionBackground};
    margin-top: 15px;
    border-radius: 6px;

    .mode-dark & {
        background: ${color.sectionBackgroundDark};
    }

    &.one-third {
        position: relative;
        overflow: hidden;
        max-width: 240px;
        min-width: 230px;
        flex: 1 1 auto;
        align-self: auto;

        @media only screen and (max-width: ${WIDTH_MOBILE}) {
            flex: 0 1 auto;
            max-width: none;
        }
    }
    &.two-third {
        margin-left: 15px;
        padding: 30px 20px;
        flex: 2 1 auto;
        align-self: auto;
        overflow: hidden;

        @media only screen and (max-width: ${WIDTH_MOBILE}) {
            flex: 0 1 auto;
            margin-left: 0px;
        }
    }
`;

export const StyledDropdownControls = styled.div`
    padding-left: 20px;
    .dropdown-controls {
        .dropdown-toggle.btn {
            box-shadow: none;
            border-color: transparent;
        }
        a.dropdown-item:active {
            background: ${convertHexToRGBA(color.orange, 0.5)};
        }
    }

    button {
        transition: all 0s linear 0s;
    }

    .mode-dark & {
        .dropdown-controls {
            .dropdown-toggle.btn {
                background: ${color.backgroundDark};
                &:focus {
                    box-shadow: 0 0 0 1px rgba(255,255,255,0.5);
                }
            }
            a.dropdown-item:active {
                background: ${convertHexToRGBA(color.orangeDark, 0.3)};
            }
        }
    }

    .mode-light & {
        .dropdown-controls {
            .dropdown-toggle.btn {
                background: ${color.background};
                color: ${color.font};
                &:focus {
                    box-shadow: 0 0 0 1px rgba(51, 51, 51,0.5);
                }
            }
        }
    }
`;