export const en = {
    header: 'ETH Gas Price Forecast System',
    eth: 'ETH',
    currency: '$',
    title: 'Gwei | STAB | ETH Gas Price Forecast System',
    gasStatTitle: 'Gas Price (Gwei)',
    gasStatDescription: 'Based On Pending Transaction of ETH Mempool',
    nextBlock: 'Next Block',
    knownTx: 'Known tx:',
    estTx: 'Est. tx:',
    lastBlock: 'Last block:',
    lastUpdate: 'Last update:',
    gwei: 'Gwei',
    txGasPriceAbove: 'txs with gas price above',
    pendingTxTitle: 'Pending Transactions',
    pendingTxDescription: 'X axis: Gas Price (Gwei), Y axis: Cumulative number of transactions',
    rapid: 'Rapid',
    fast: 'Fast',
    standard: 'Standard',
    slow: 'Slow',
    seconds: 'Seconds',
    minutes: 'Minutes',
    minute: 'Minute',
    histGasPrice: 'Historical Gas Price',
    histGasPriceDescription: 'Rapid/Fast: the arithmetic average value of gas prices for blocks mined within a given interval',
    histSelectBlock: 'Latest Block',
    histSelectMinuteOne: '1 Minute',
    histSelectMinuteTen: '10 Minutes',
    histSelectHourOne: '1 Hour',
    histSelectDayOne: '1 Day',
    avgGasPrice: 'Average Gas Price',
    avgGasPriceDescription: 'Average hourly gas price adjusted to your timezone',
    btd: 'buy the dip',
};