import React, { useState, useEffect } from 'react';
import useWebSocket/*, { ReadyState }*/ from 'react-use-websocket';
import { decode, decodeAsync, encode } from "@msgpack/msgpack";
import { 
    useAppDispatch, 
    useAppSelector, 
    selectHistoricalGas,
    updateGasStats,
    updateGasCounts,
    updateEthPrice,
    updateHistoricalGas,
    updateAverageGas,
} from '../redux';


async function decodeFromBlob(blob: Blob): Promise<{[key: string]: any}> {
    if (blob.stream) {
      // Blob#stream(): ReadableStream<Uint8Array> (recommended)
      return await decodeAsync(blob.stream()) as Promise<{[key: string]: any}>;
    } else {
      // Blob#arrayBuffer(): Promise<ArrayBuffer> (if stream() is not available)
      return decode(await blob.arrayBuffer()) as Promise<{[key: string]: any}>;
    }
  }

function logSocketError(err: any) {
    console.error(err);
}


export function WebsocketProvider() {
    const { 
        payloadType: historicalPayloadType
    } = useAppSelector(selectHistoricalGas);
    const dispatch = useAppDispatch();
    const [reconnectInterval, ] = useState((Math.floor(Math.random() * (15 - 8 + 1)) + 8) * 1000); // 8-15s
    const [socketUrl, ] = useState(process.env.REACT_APP_WS_API || '');

    const {
        sendMessage,
        lastMessage,
        readyState,
    } = useWebSocket(
        socketUrl,
        {
            shouldReconnect: (_closeEvent) => true,
            reconnectAttempts: 20,
            reconnectInterval // 15s max, 300s total max
        }
    );
    
    // const connectionStatus = {
    //     [ReadyState.CONNECTING]: 'Connecting',
    //     [ReadyState.OPEN]: 'Open',
    //     [ReadyState.CLOSING]: 'Closing',
    //     [ReadyState.CLOSED]: 'Closed',
    //     [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
    // }[readyState];

    const historicalGasCmd = {
        block: 'hb',
        minute_1: 'hm1', 
        minute_10: 'hm10',
        hour_1: 'hh1',
        day_1: 'hd1'
    }

    useEffect(() => {
        // console.log(`ws: ${connectionStatus}`);
        if (readyState === 1) {
            sendMessage(
                encode(
                    {s: historicalGasCmd[historicalPayloadType]}
                )
            );
            sendMessage(
                encode({a: 'aw'})
            );
        }
    }, [readyState]);

    useEffect(() => {
        if (readyState === 1) {
            sendMessage(
                encode({s: historicalGasCmd[historicalPayloadType]})
            );
        } 
    }, [historicalPayloadType]);

    useEffect(() => {
        let data: any = {};
        if (lastMessage !== null && lastMessage.isTrusted && lastMessage.data) {

            decodeFromBlob(lastMessage.data)
            .then((message) => {
                if (message?.d && message?.t) {
                    // data = parseMessageData(message.d);
                    data = message.d;
                    // console.log(data);
    
                    if (message.t === 'gc') {
                        if (!!data.map) {
                            dispatch(updateGasCounts(data.map((d: any) => { return {gasPrice: d.g, count: d.c}})));
                        }
                    } else if (message.t === 'gs') {
                        dispatch(updateGasStats(data));
                    } else if (message.t === 'ep') {
                        if (data?.usd?.error?.message) {
                            console.error(data?.usd?.error?.message);
                            return;
                        }
                        dispatch(updateEthPrice(data));
                    } else if (message.t === 'aw' && !!data?.map) {
                        dispatch(
                            updateAverageGas(
                                data.map((d: any) => {
                                    return {
                                        time: d?.t?.toString(),
                                        rapid: d?.r,
                                        fast: d?.f
                                    }
                                })
                            )
                        );
                    } else if (message.t === historicalGasCmd.block && !!data?.map) {
                        dispatch(
                            updateHistoricalGas(
                                data.map((d: any) => {
                                    return {
                                        blocknumber: d?.b,
                                        rapid: d?.r,
                                        fast: d?.f
                                    }
                                })
                            )
                        );
                    } else if ((
                        message.t === historicalGasCmd.minute_1 ||
                        message.t === historicalGasCmd.minute_10 ||
                        message.t === historicalGasCmd.hour_1 ||
                        message.t === historicalGasCmd.day_1
                    ) && !!data?.map) {
                        dispatch(
                            updateHistoricalGas(
                                data.map((d: any) => {
                                    return {
                                        time: d?.t.toString(),
                                        rapid: d?.r,
                                        fast: d?.f
                                    }
                                })
                            )
                        );
                    } else {
                        logSocketError(`Invalid WS Message Type: ${message.t}`);
                    }
                } else if (!message?.d && !message.t) {
                    logSocketError(`Invalid WS Message Type or Data:`);
                    console.log(lastMessage);
                    decodeFromBlob(lastMessage.data).then(console.log);
                }
            })
            .catch((err) => {
                console.error('WS Message Parse Error:');
                console.log(err);
            });
        } else if (lastMessage !== null) {
            logSocketError(`Invalid WS Message: ${lastMessage}`);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lastMessage]);


    // const handleClickSendMessage = useCallback(() =>
    //     sendMessage('Hello'), []);

    return <></>;
}