import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

// Types
type GasStatsState = {
    readonly baseFeeGas: number;
    readonly block: number;
    readonly gasFast: number;
    readonly gasRapid: number;
    readonly gasSlow: number;
    readonly gasStandard: number;
    readonly txLenEst: number;
    readonly txLenKnown: number;
};

// Initial State
const initialState = {
    baseFeeGas: 0,
    block: 0,
    gasFast: 0,
    gasRapid: 0,
    gasSlow: 0,
    gasStandard: 0,
    txLenEst: 0,
    txLenKnown: 0,
} as GasStatsState;

export const sliceGasStats = createSlice({
    name: 'gasStats',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        update: (state, action: PayloadAction<GasStatsState>) => {
            return action.payload;
        },
    },
});

export const { update: updateGasStats } = sliceGasStats.actions;
export const selectGasStats = (state: RootState) => state.gasStats;
export const selectBlockStats = (state: RootState) => {
    return {
        txLenEst: state.gasStats.txLenEst,
        txLenKnown: state.gasStats.txLenKnown,
        block: state.gasStats.block
    }
};
export const { reducer: reducerGasStats } = sliceGasStats;

