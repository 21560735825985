import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

// Types
type AverageGasDataType = {
    readonly time: number;
    readonly rapid: number;
    readonly fast: number;
    // readonly txKnown: number;
    // readonly txEst: number;
};

type AverageGasType = {
    readonly payloadType: 'hour_1';
    readonly data: AverageGasDataType[];
};


// Initial State
const initialState = {
    payloadType: 'hour_1',
    data: []
} as AverageGasType;

export const sliceAverageGas = createSlice({
    name: 'gasAverage',
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        updateData: (state, action: PayloadAction<AverageGasDataType[]>) => {
            state.data = action.payload;
        }
    },
});

export const { 
    updateData: updateAverageGas,
} = sliceAverageGas.actions;
export const selectAverageGas = (state: RootState) => state.gasAverage;
export const { reducer: reducerAverageGas } = sliceAverageGas;

