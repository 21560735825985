import React from 'react';

export function LogoTitle() {
    return (
        <svg width="105" height="38" viewBox="0 0 105 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <linearGradient id="gradient2" x2="0%" y2="100%">
                <stop className="start" offset="0%"/>
                <stop className="end" offset="100%"/>
            </linearGradient>
            <path d="M1.37201 37.668L5.08001 33.78H13.972L17.464 37.668H1.37201ZM18.616 36.084L14.728 32.376V23.52L18.616 19.992V36.084ZM4.46801 21.288C3.62801 20.616 2.77601 19.956 1.91201 19.308L4.46801 17.4H14.296L16.816 19.308L14.296 21.288H4.46801ZM1.33601 1.09201H17.428L13.864 4.98001H5.04401L1.33601 1.09201ZM0.184006 18.732V2.64001L4.07201 6.13201V15.06L0.184006 18.732Z" fill="black"/>
            <path d="M40.173 36.084L36.285 32.376V23.52L40.173 19.992V36.084ZM27.141 4.33201C26.301 3.66001 25.461 3.02401 24.621 2.42401L27.141 0.480011H49.245L51.765 2.42401L49.245 4.33201H40.065V18.732L36.177 15.24V6.34801L38.301 4.33201H27.141Z" fill="black"/>
            <path d="M57.8779 19.992L61.7659 23.736V32.556L57.8779 36.084V19.992ZM76.2019 36.084L72.3139 32.376V23.52L76.2019 19.992V36.084ZM62.0539 21.288C61.2139 20.616 60.3619 19.956 59.4979 19.308L62.0539 17.4H71.8819L74.4019 19.308L71.8819 21.288H62.0539ZM58.9219 1.09201H75.0139L71.4499 4.98001H62.6299L58.9219 1.09201ZM57.7699 18.732V2.64001L61.6579 6.13201V15.06L57.7699 18.732ZM76.0939 2.64001V18.732L72.2059 15.24V6.34801L76.0939 2.64001Z" fill="black"/>
            <path d="M87.7509 37.668L91.4589 33.78H100.351L103.843 37.668H87.7509ZM86.6709 19.992L90.5589 23.736V32.556L86.6709 36.084V19.992ZM104.995 36.084L101.107 32.376V23.52L104.995 19.992V36.084ZM90.8469 21.288C90.0069 20.616 89.1549 19.956 88.2909 19.308L90.8469 17.4H100.675L103.195 19.308L100.675 21.288H90.8469ZM87.7149 1.09201H103.807L100.243 4.98001H91.4229L87.7149 1.09201ZM86.5629 18.732V2.64001L90.4509 6.13201V15.06L86.5629 18.732ZM104.887 2.64001V18.732L100.999 15.24V6.34801L104.887 2.64001Z" fill="black"/>
        </svg>
    );
}