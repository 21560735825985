import React from 'react';

export function TildeIcon() {
    return (
        <svg 
            version="1.0" 
            xmlns="http://www.w3.org/2000/svg"
            width="1280.000000pt" 
            height="424.000000pt" 
            viewBox="0 0 1280.000000 424.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g transform="translate(0.000000,424.000000) scale(0.100000,-0.100000)" stroke="none">
                <path d="M8330 4234 c-408 -26 -688 -67 -994 -146 -450 -114 -807 -269 -1161 -503 -279 -185 -487 -374 -810 -735 -328 -367 -480 -499 -706 -615 -460 -236 -1082 -257 -1851 -64 -387 97 -674 196 -1418 491 -451 178 -655 256 -870 329 -155 54 -421 133 -482 144 l-38 7 0 -1150 c0 -1087 1 -1151 18 -1156 9 -3 91 -21 180 -41 200 -45 461 -114 947 -250 1214 -341 1751 -454 2475 -521 233 -22 836 -25 1045 -6 793 74 1452 287 1995 646 268 178 478 361 775 676 333 354 462 471 640 580 464 285 1124 324 1938 116 345 -89 633 -188 1217 -418 837 -330 1015 -395 1320 -483 102 -29 200 -56 218 -60 l32 -7 -2 1158 -3 1158 -40 9 c-193 41 -692 173 -1130 297 -1230 350 -1748 460 -2464 526 -156 14 -709 26 -831 18z"/>
            </g>
        </svg>
    );
}