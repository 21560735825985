import React from 'react';

export function LogoGasSlow() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1100 1100">
            <path d="M224.8,755.93h98.5l40.18-40.19L325,677.24l-40.83,40.83a298.77,298.77,0,0,1-34.48-140c0-5.59.17,6.42.47.88h67.15V524.53H258.12c12-49.61,36.54-112.94,72.05-151l46.62,46.62,38.5-38.5-44.55-44.55A297.33,297.33,0,0,1,521,279.15V343.2h54.45V278.85a297.46,297.46,0,0,1,156.78,60.5L690,381.62l38.5,38.5,43.89-43.89c33.94,37.31,57.52,99.33,69.3,147.43H781.83V578.1h68c.34,5.83.53-5.88.53,0a298.79,298.79,0,0,1-33.88,138.82L775.9,676.37l-38.5,38.49,41.06,41.07h96.73A370.28,370.28,0,0,0,812,316.06q-13.15-13.14-27.4-24.82l-2.14-2.14-.23.23A370.58,370.58,0,0,0,224.8,755.93Z"/>
            <rect x="319.92" y="823.1" width="456.64" height="69.37" transform="translate(1096.49 1715.58) rotate(180)"/>
            <path d="M495.4,559.49,365.15,615,508,616a55.32,55.32,0,1,0-12.59-56.54Z"/>
        </svg>
    );
}