import './asset/font/Roboto-Bold.woff2';
import './asset/font/Roboto-Bold.woff';
import './asset/font/Roboto-Regular.woff2';
import './asset/font/Roboto-Regular.woff';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@csstools/normalize.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store } from './redux';
import { WebsocketProvider } from './component';

if (process.env.REACT_APP_ENABLE_SENTRY) {
  Sentry.init({
    dsn: 'https://0fce8c13e1ab4a28afc9f633a479a67e@o336313.ingest.sentry.io/5428975',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
} 

ReactDOM.render(
    <Provider store={store}>
      <App />
      <WebsocketProvider />
    </Provider>,
  document.getElementById('root')
);

console.log(`
███████╗████████╗ █████╗ ██████╗    ██╗ ██████╗ 
██╔════╝╚══██╔══╝██╔══██╗██╔══██╗   ██║██╔═══██╗
███████╗   ██║   ███████║██████╔╝   ██║██║   ██║
╚════██║   ██║   ██╔══██║██╔══██╗   ██║██║   ██║
███████║   ██║   ██║  ██║██████╔╝██╗██║╚██████╔╝
╚══════╝   ╚═╝   ╚═╝  ╚═╝╚═════╝ ╚═╝╚═╝ ╚═════╝ 
ETH Gas Price Forecast System

https://twitter.com/stab_io

`);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
